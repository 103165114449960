<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep2"
            v-on:formFunction="formFunction"
          />
        </div>
        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 3">
      <form id="step3" v-on:submit.prevent="submitStep3">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 3 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 3"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altLinkText="Læs mere om vores veteranbilforsikring"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container" :class="{ small: product.step != 3 }">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
    >
      Hos os må du køre, når du vil, og hvor du vil i din veteranbil, så længe du har en primær bil, og din veteranbil er godkendt til veterankørsel hos SKAT. Vi har nemlig fri kilometer – og det er uden tillægspris for kørsel i vinterhalvåret. Derudover dækker vores veteranbilforsikring også tyveri af nummerplader, herunder historiske nummerplader, uden selvrisiko, ligesom du heller ikke har en selvrisiko på rene ansvarsskader, når du ikke samtidig har en kaskoskade på din egen bil. Vi har desuden intet krav om originalitet eller klubmedlemskab.
    </product-footer>
  </div>
</template>
<script>
import M21 from "@/assets/json/M21.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import debounce from "lodash/debounce";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";

export default {
  name: "Veteranbilforsikring",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "M21",
      product: this.copy(M21),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Set registration number from input
      this.product.fields.registrationNumber.value = this.$route.query.input;

      // If value is set, run function
      if (this.product.fields.registrationNumber.value) {
        this.getRegistrationNumber();
      }

      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push to Veteranbilforsikring without input
      this.$router
        .push({ name: "Veteranbilforsikring", query: query })
        .catch(() => {});
    }
  },
  computed: {
    /*
     * Check requirements for step 1
     */
    requirementsStep1Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 1) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
  },
  methods: {
    /*
     * If registration data is empty
     */
    emptyRegistationData() {
      // Only if registration toggle is not set and registration number is filled out
      if (!this.product.fields.registrationToggle.value && this.product.fields.registrationNumber.value) {
        this.product.fields.registrationNumber.error = "Tryk på knappen for at hente oplysninger på din bil.";
      }
    },
    /*
     * Reset vehicle crm id field
     */
    resetVehicleCrmID() {
      this.product.fields.vehicleCrmID.value = null;
    },
    /*
     * Get data for registration number from api
     */
    getRegistrationNumber() {
      let self = this;

      // Reset car fields
      self.resetCarFields();

      // Remove error if it's there
      self.product.fields.registrationNumber.error = null;

      // Disable toogle button so that does not interfere
      self.product.fields.registrationToggle.disabled = true;

      // Set registration field to loading
      self.product.fields.registrationNumber.loading = true;

      // Create api string
      let apiString = `/api/vehicle/information/${self.product.fields.registrationNumber.value}`;

      // Get options list
      self.axios
        .get(apiString, {
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then((response) => {
          self.product.fields.registrationData.value = response.data;
          self.product.fields.vehicleCrmField.value = `${response.data.extraCarInformation.maerke} ${response.data.extraCarInformation.model}`;

          // Set model year if there
          if (response.data.data.modelYear) {
            self.$set(
              self.product.fields.modelYear,
              "value",
              response.data.data.modelYear
            );
          }

          // Set horsepower if there
          if (response.data.data.horsepower) {
            self.$set(
              self.product.fields.horsepower,
              "value",
              response.data.data.horsepower
            );
          }

          // Set chassisNumber if there
          if (response.data.data.chassisNumber) {
            self.$set(
              self.product.fields.chassisNumber,
              "value",
              response.data.data.chassisNumber
            );
          }

          // Set veteran status based on api
          self.$set(
            self.product.fields.isVeteran,
            "value",
            self.getVeteranPermission(response.data)
          );

          self.product.fields.registrationNumber.loading = false;

          // Enable toogle button
          self.product.fields.registrationToggle.disabled = false;
        })
        .then(() => {
          // Focus on vehicle crm field field
          document.getElementById("vehicleCrmField").focus();

          // Check if step requirements are met
          if (!self.requirementsStep1Met) {
            self.error.step1 = {
              headline: "Vi kan desværre ikke beregne en pris online",
              text: 'En af de kritiske informationer vedr. køretøjet mangler fra SKAT for at kunne beregne en pris online. Indtast oplysningerne om din bil manuelt ved at trykke på knappen "Jeg kender ikke registreringsnummeret", eller udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.',
              button: {
                text: "Kontakt mig",
                function: "messageBox",
              },
            };
          }
        })
        .catch(() => {
          self.product.fields.registrationNumber.error = `Vi kunne ikke finde køretøjsinformation for "${self.product.fields.registrationNumber.value.toUpperCase()}". Dobbelttjek at du har tastet det rigtige nummer ind i feltet.\nHvis du ikke kender din nummerplade, kan du finde din bil manuelt ved at trykke på knappen nedenfor`;
          self.product.fields.registrationNumber.loading = false;

          // Enable toogle button
          self.product.fields.registrationToggle.disabled = false;
        });
    },
    /*
     * Reset car fields that is updated from registration field
     */
    resetCarFields() {
      this.product.fields.chassisNumber.value = null;
      this.product.fields.vehicleCrmID.value = null;
      this.product.fields.vehicleCrmField.value = null;
      this.product.fields.vehicleCrmField.selected = null;
      this.product.fields.modelYear.value = null;
      this.product.fields.weightOwn.value = null;
      this.product.fields.vehicleName.value = null;
      this.product.fields.horsepower.value = null;
      this.product.fields.marketPrice.value = null;
      this.product.fields.tracking.value = false;
      this.product.fields.towHitch.value = false;
      this.product.fields.isVeteran.value = null;
      this.error.step1 = null;
    },
    // Function when you choose vehicle field
    vehicleCrmField(value) {
      this.product.fields.vehicleCrmID.value = value.key;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          // If weird error shows, run function again
          if (response.data.objects && response.data.objects.length < 3) {
            self.calculateEveryPrice();
            return;
          }

          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Function for input on registration number
     */
    registrationNumber() {
      this.resetCarFields();
      this.product.fields.registrationData.value = null;
      this.product.fields.registrationNumber.error = null;
      this.error.step1 = null;

      // If the registration number is filled out, change to upper case
      if (this.product.fields.registrationNumber.value) {
        this.product.fields.registrationNumber.value =
          this.product.fields.registrationNumber.value.toUpperCase();
      }
    },
    /*
     * Blur function on registration number
     */
    leaveRegistrationNumber() {
      if (
        !this.product.fields.registrationData.value &&
        !this.product.fields.registrationNumber.loading &&
        this.product.fields.registrationNumber.value &&
        this.product.fields.registrationNumber.value.length > 1
      ) {
        this.getRegistrationNumber();
      }
    },
    /*
     * Function when the toggle for registration number changes
     */
    toggleRegistrationData() {
      // Reset car fields
      this.resetCarFields();

      if (this.product.fields.registrationToggle.value) {
        // Remove required from registration number
        this.product.fields.registrationNumber.required = false;
        this.product.fields.registrationNumber.conditions = ["registrationData"];

        // Remove required from registration number
        this.product.fields.registrationData.required = false;

        // Remove content of registration number
        this.product.fields.registrationNumber.value = null;
        this.product.fields.registrationNumber.error = null;

        // Remove content of registration data
        this.product.fields.registrationData.value = null;

        // Set veteran status to type checkbox
        this.product.fields.isVeteran.type = "radio2";

        // Change requirements
        this.product.fields.vehicleCrmField.requires = [];
        this.product.fields.modelYear.requires = ["vehicleCrmID"];
        this.product.fields.horsepower.requires = ["vehicleCrmID", "modelYear"];
        this.product.fields.marketPrice.requires = ["vehicleCrmID", "modelYear", "horsepower"];
      } else {
        // Add required from registration number
        this.product.fields.registrationNumber.required = true;
        this.product.fields.registrationNumber.conditions = [];

        // Add required from registration number
        this.product.fields.registrationData.required = true;

         // Set veteran status to type hidden
        this.product.fields.isVeteran.type = "Hidden";

        // Change requirements
        this.product.fields.vehicleCrmField.requires = ["registrationData"];
        this.product.fields.modelYear.requires = ["registrationData", "vehicleCrmID"];
        this.product.fields.horsepower.requires = ["registrationData", "vehicleCrmID", "modelYear"];
        this.product.fields.marketPrice.requires = ["registrationData", "vehicleCrmID", "modelYear", "horsepower"];
      }
    },
    // Returns boolean to show, whether the registration data has
    getVeteranPermission(registrationData) {
      try {
        let tilladelse = false;
        registrationData.extraCarInformation.tilladelse.forEach((element) => {
          if (element.tilladelseNummer == "14") tilladelse = true;
        });
        return tilladelse;
      } catch {
        return false;
      }
    },
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    async submitStep2() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[1].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(self.product, self.product.step);

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set step 1 to loading
      self.product.steps[1].loading = false;
    },
    /*
     * Submit step 3 in form
     */
    submitStep3() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
